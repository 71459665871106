import React from 'react';
import ReactDOM from 'react-dom';

const LoadingFullScreenPortal = () => {
  // Find or create a portal container
  const portalRoot = document.getElementById('loading-root') || createPortalRoot();

  return ReactDOM.createPortal(
    <div className="loading-screen loading-screen-full">
      <div className="animation" />
    </div>,
    portalRoot
  );
};

// Helper function to create a portal root if it doesn't exist
function createPortalRoot() {
  const portalRoot = document.createElement('div');
  portalRoot.setAttribute('id', 'loading-root');
  document.body.appendChild(portalRoot); // Append it to the body
  return portalRoot;
}

export default LoadingFullScreenPortal;
