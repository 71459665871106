/* eslint-disable tailwindcss/no-custom-classname */
import React, { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import NodeLink from '@frontastic/catwalk/src/js/app/nodeLink';
import emptyEntity from '@frontastic/catwalk/src/js/helper/emptyEntity';
import { generateId } from '@frontastic/common';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import BrandFlyout from './BrandFlyout';
import { ReactComponent as LogoAbsoluteRun } from '../../../../../icons/sport2000-icons/logos/absoluterun.svg';
import LogoSport2000 from '../../../../../icons/sport2000-icons/logos/sport2000_home_of_experts_o.png';
import Sport2000Logo from '../../../atoms/sport2000Logo';
import { TransitionLink } from 'components/commercetools-ui/transition-link';
import Link from 'next/link';
import { useRouter } from 'next/router';

const LazyNavigationFlyout = dynamic(() => import('./NavigationFlyoutV2'), {
  ssr: false,
});

const DesktopMenu = ({
  intl,
  currentTree,
  currentNavigation,
  onFlyoutToggle,
  categorySidebar,
  themeName,
  categoryBrand,
  saleCategory,
  isCategoryPage
}) => {
  const router = useRouter()
  let mouseOverTimeout;

  const currentNavigationId = currentNavigation && (currentNavigation.id || currentNavigation.nodeId);
  const [expandedItemId, setExpandedItemId] = useState('');

  const [currentLogoHref, setCurrentLogoHref] = useState('/');
  const [currentLogo, setCurrentLogo] = useState(null);

  const changeLogoRunningExperts = () => {
    setCurrentLogo(<Sport2000Logo className={'menu-animation'} name={'RunningExperts'} width={'190px'} />);
    setCurrentLogoHref('/running-experts');
  };

  const changeLogoAbsoluteTeamsport = () => {
    setCurrentLogo(<Sport2000Logo name={'AbsoluteTeamsport_2line'} width={'190px'} />);
    setCurrentLogoHref('/absolute-teamsport');
  };

  const changeLogoAbsoluteRun = () => {
    setCurrentLogo(<LogoAbsoluteRun width={190} height={36} className={'menu-animation'} />);
    setCurrentLogoHref('/absolute-run');
  };

  const changeDefaultLogo = () => {
    setCurrentLogo(<Sport2000Logo className={'menu-animation'} name={'Sport2000'} height={'88px'} logoDefault={LogoSport2000.src} />);
    setCurrentLogoHref('/');
  };

  const changeLogoSkiExpert = () => {
    setCurrentLogoHref('/ski-expert');
    setCurrentLogo(<Sport2000Logo name={'SkiExperts'} width={'190px'} />);
  };

  useEffect(() => {
    switch (themeName) {
      case 'theme-ski-experts':
        return changeLogoSkiExpert();

      case 'theme-running-expert':
        return changeLogoRunningExperts();

      case 'theme-absolute-teamsport':
        return changeLogoAbsoluteTeamsport();

      case 'theme-absolute-run':
        return changeLogoAbsoluteRun();

      default:
        return changeDefaultLogo();
    }
  }, [currentTree, themeName]);

  const handleMouseEnter = (item, itemId) => {
    mouseOverTimeout = setTimeout(() => {
      if (item.children || item.brandLogo || item.brandList) {
        return setExpandedItemId(itemId);
      }
    }, 500);
  };

  const handleMouseLeave = () => {
    if (expandedItemId) {
      setExpandedItemId('');
    }

    clearTimeout(mouseOverTimeout);
  };

  useEffect(() => {
    if (onFlyoutToggle) {
      onFlyoutToggle(expandedItemId);
    }
  }, [expandedItemId]);

  const handleCloseFlyout = () => {
    setExpandedItemId('');
  }

  const onNavigateCategoryPage = (event, href) => {
    event.preventDefault()
    if (isCategoryPage) {
      window.location.href = href
      return
    }

    handleCloseFlyout()
    router.push(href)
  }

  const renderHeaderNavigation = () => {
    if (currentTree?.children && currentTree.children.length) {
      return currentTree.children.map((item) => {
        const itemId = item.nodeId;

        /// @COFIXME[page-folder](FLBML-114): Replace NodeLink with PageFolderLink?
        return (
          <li key={itemId}>
            <NodeLink
              node={item}
              className={classnames('menu-animation h-full', {
                selected: itemId === currentNavigationId && expandedItemId === itemId,
              })}
            >
              {item.name}
            </NodeLink>
          </li>
        );
      });
    }

    if (currentTree?.length) {
      return currentTree.map((item) => {
        const itemId = item.id || item.tree?.nodeId || generateId();
        const filterFieldAllText = item.custom?.customFieldsRaw?.filter((name) => name.name === 'showAllText');
        const textLinkAllProduct =
          filterFieldAllText && filterFieldAllText.length > 0 ? filterFieldAllText[0]?.value : '';

        return (
          <li key={itemId} onMouseEnter={() => handleMouseEnter(item, itemId)} onMouseLeave={handleMouseLeave}>
            <a
              href={item.url ? item.url : `/c/${item.id}/${item.slug}`}
              className={classnames('menu-animation h-full', {
                selected: expandedItemId === itemId,
              })}
              onClick={(e) => onNavigateCategoryPage(e, item.url ? item.url : `/c/${item.id}/${item.slug}`)}
            >
              {item.name}
            </a>

            {expandedItemId === itemId && (
              <LazyNavigationFlyout
                expandedItemId={expandedItemId}
                itemId={itemId}
                item={item}
                onClose={handleCloseFlyout}
                categorySidebar={categorySidebar}
                themeName={themeName}
                textLinkAllProduct={textLinkAllProduct}
                isCategoryPage={isCategoryPage}
              />
            )}
          </li>
        );
      });
    }

    return null;
  };

  const renderBrandNavigation = () => {
    if (!categoryBrand || (!categoryBrand.brandLogo?.data?.length && !categoryBrand.brandList?.data?.length)) {
      return null;
    }

    const { itemId, brandLogo, brandList } = categoryBrand;

    return (
      <li onMouseEnter={() => handleMouseEnter(categoryBrand, itemId)} onMouseLeave={handleMouseLeave}>
        <a
          className={classnames('menu-animation h-full', {
            selected: expandedItemId === itemId,
          })}
        >
          <FormattedMessage id={'header.marken'} />
        </a>

        <BrandFlyout
          expandedItemId={expandedItemId}
          itemId={itemId}
          brandLogo={brandLogo}
          brandList={brandList}
          onClose={handleCloseFlyout}
          themeName={themeName}
        />
      </li>
    );
  };

  const renderSaleNavigation = () => {
    if (!saleCategory) {
      return null
    }

    return (
      <li className={'nav-sale'}>
        <TransitionLink
          href={saleCategory.url}
          title={saleCategory.name}
          className={classnames('h-full menu-animation')}
        >
          {saleCategory.name}
        </TransitionLink>
      </li>
    )
  }

  return (
    <div className="header-navigation">
      <div className="header-navigation--logo">
        <TransitionLink href={currentLogoHref} title={intl.formatMessage({ id: 'header.sport2000IconAltTitle' })}>
          {currentLogo}
        </TransitionLink>
      </div>

      <div
        className={'hidden lg:flex justify-center items-center header-navigation--list nav-level-1'}
      >
        {renderHeaderNavigation()}
        {renderBrandNavigation()}
        {renderSaleNavigation()}
      </div>
    </div>
  )
}

DesktopMenu.propTypes = {
  intl: PropTypes.object.isRequired,
  currentTree: PropTypes.PropTypes.oneOfType([PropTypes.objectOf(PropTypes.any), PropTypes.arrayOf(PropTypes.any)])
    .isRequired,
  currentNavigation: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.any)]).isRequired,
  onFlyoutToggle: PropTypes.func,
  categorySidebar: PropTypes.any,
  themeName: PropTypes.any,
  categoryBrand: PropTypes.any,
  saleCategory: PropTypes.any
};

export default connect((globalState) => ({
  node: globalState.node.nodes[globalState.node.currentNodeId] || globalState.node.last.node || emptyEntity,
}))(injectIntl(DesktopMenu));
