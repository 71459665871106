import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useDeviceType } from '@frontastic/catwalk/src/js/helper/hooks/useDeviceType'
import Mobile from './Mobile'
import Desktop from './Desktop'
import { useNavPath } from './mainMenuState'
import {DomElement} from '@frontastic/bridge/js/types';
import { useRouter } from "next/router";

const findChildren = (children, currentNodeId) => children.find((category) => category.nodeId === currentNodeId || (category.children && findChildren(category.children, currentNodeId)))

const getCurrentTopCategory = (node, topCategories, currentNodeId, isAbsoluteSportTheme, isBlog, isAbsoluteRunExpertTheme, isAbsoluteRunTheme) => {
    const lengthCategory = topCategories.length
    if (lengthCategory === 1) {
        return 0
    }

    if (lengthCategory > 1) {
        if (isAbsoluteSportTheme) {
            return 0
        }

        if (isAbsoluteRunExpertTheme) {
            return 2
        }

        if (isAbsoluteRunTheme) {
            return 1
        }
    }

    const index = topCategories.findIndex((category) => category.tree?.nodeId === currentNodeId || (category.tree?.children && findChildren(category.tree?.children, currentNodeId)))

    if (index === -1) {
        return 1
    }

    return index
}

const generateSaleCategory = (category, currentTopCategory) => {
    const currentCats = category?.[currentTopCategory]
    let selectedCat
    if (currentCats.length) {
        selectedCat = currentCats[0]
    } else {
        selectedCat = currentCats
    }
    return {
        id: 'sale',
        name: 'Sale',
        key: 'sale',
        slug: `sale-${selectedCat.slug}`,
        url: selectedCat.url.replace('/c/', '/') + '/sale/',
        children: null,
        custom: null
    }
};

const MainMenu = ({
    category,
    logoImageWithText,
    logoImageOnly,
    goToCartPage,
    goToProfilePage,
    goToCheckoutPage,
    isOpenMiniCart,
    refMiniCart,
    cart,
    closeMiniCart,
    node,
    onFlyoutToggle,
    placeholderSearch,
    context,
    searchFAQ,
    categorySidebar,
    goToWishlistPage,
    searchHitsOpen,
    setSearchHitsOpen,
    themeName,
    setThemeName,
    absoluteTeamsportBrand,
    runningExpertBrand,
    nbOfCharactersToTriggerSearch,
}) => {
    const currentNodeId = useSelector((state) => {
        const routeParameter = state.app?.route?.parameters
        const nodeId = state.node?.currentNodeId
        return routeParameter?.nodeId || routeParameter?.id || nodeId
    })
    const [currentTopCategory, setCurrentTopCategory] = useState(getCurrentTopCategory(node, category, currentNodeId))
    const [currentNavigation, setCurrentNavigation] = useNavPath('')
    const saleCategory = generateSaleCategory(category, currentTopCategory)
    const { themeStore } = useSelector((state) => state['select-store'])
    const isDesktop = useDeviceType() === 'desktop'

    const { query } = useRouter()

    const getCategoryInSearchSuggest = () => {
        return query.categoryName?.toLocaleLowerCase() || ''
    }

    // we can't use `document` as a dependency of useEffect because of SSR, so we use an extracted variable
    const bodyClassName = typeof document !== 'undefined' ? document.body.className : ''

    useEffect(() => {
        const isNodeProductType = node.nodeType === 'product'
        const isAbsoluteSportTheme = window.location.href.indexOf('absolute-teamsport') > -1
            || window.location.href.indexOf('/fussball') > -1
            || (isNodeProductType && document.body.classList.contains('absolute-teamsport--theme'))
            || getCategoryInSearchSuggest() === 'fussball'
            || themeStore === 'absolute_teamsport'

        const isAbsoluteRunExpertTheme = window.location.href.indexOf('absolute-sports/absolute-run') > -1
            || window.location.href.indexOf('/running') > -1
            || window.location.href.indexOf('running-experts') > -1
            || (isNodeProductType && document.body.classList.contains('absolute-run--theme'))
            || getCategoryInSearchSuggest() === 'running'
            || themeStore === 'running_expert'

        const isAbsoluteRunTheme = window.location.href.indexOf('/absolute-run') > -1
        const isSkiExpertTheme = window.location.href.indexOf('/ski-experts') > -1

        const isBlog = window.location.href.indexOf('blog') > -1

        if (isAbsoluteSportTheme) {
            setThemeName('theme-absolute-teamsport')
        } else if (isAbsoluteRunExpertTheme) {
            setThemeName('theme-running-expert')
        } else if (isAbsoluteRunTheme) {
            setThemeName('theme-absolute-run')
        } else if (isSkiExpertTheme) {
            setThemeName('theme-ski-experts')
        } else {
            setThemeName('')
        }

        setCurrentTopCategory(getCurrentTopCategory(node, category, currentNodeId, isAbsoluteSportTheme, isBlog, isAbsoluteRunExpertTheme, isAbsoluteRunTheme))
    }, [currentNodeId, node.nodeType, bodyClassName, category])

    const handleSelectTopCategory = (categoryId) => {
        setCurrentNavigation('')
        setCurrentTopCategory(categoryId)
    }

    const { cartItemsCount } = useSelector((state) => {
        const cartLineItems = state.cart?.cart?.data?.lineItems || []

        return {
            cartItemsCount: cartLineItems.reduce((accumulator, currentValue) => accumulator + currentValue.count, 0),
        }
    })

    const { wishListLineItemsCount } = useSelector((state) => {
        const wishListLineItems = state.wishlist?.wishlist?.data?.lineItems || []

        return {
            wishListLineItemsCount: wishListLineItems.reduce((accumulator, currentValue) => accumulator + currentValue.count, 0),
        }
    })

    // For performance reasons we hide the mobile navigation on desktop and vice versa.
    if (!isDesktop) {
        return (
            <Mobile
                category={category}
                absoluteTeamsportBrand={absoluteTeamsportBrand}
                runningExpertBrand={runningExpertBrand}
                logoImageWithText={logoImageWithText}
                currentTopCategory={currentTopCategory}
                handleSelectTopCategory={handleSelectTopCategory}
                cartItemsCount={cartItemsCount}
                placeholderSearch={placeholderSearch}
                context={context}
                searchFAQ={searchFAQ}
                node={node}
                searchHitsOpen={searchHitsOpen}
                setSearchHitsOpen={setSearchHitsOpen}
                themeName={themeName}
                nbOfCharactersToTriggerSearch={nbOfCharactersToTriggerSearch}
                saleCategory={saleCategory}
            />
        )
    }

    return (
        <Desktop
            category={category}
            cart={cart}
            logoImageWithText={logoImageWithText}
            logoImageOnly={logoImageOnly}
            currentTopCategory={currentTopCategory}
            currentNavigation={currentNavigation}
            cartItemsCount={cartItemsCount}
            goToCartPage={goToCartPage}
            goToProfilePage={goToProfilePage}
            goToCheckoutPage={goToCheckoutPage}
            refMiniCart={refMiniCart}
            isOpenMiniCart={isOpenMiniCart}
            closeMiniCart={closeMiniCart}
            node={node}
            placeholderSearch={placeholderSearch}
            onFlyoutToggle={onFlyoutToggle}
            themeName={themeName}
            context={context}
            searchFAQ={searchFAQ}
            categorySidebar={categorySidebar}
            wishListLineItemsCount={wishListLineItemsCount}
            goToWishlistPage={goToWishlistPage}
            setSearchHitsOpen={setSearchHitsOpen}
            searchHitsOpen={searchHitsOpen}
            themeSearchPage={getCategoryInSearchSuggest()}
            absoluteTeamsportBrand={absoluteTeamsportBrand}
            runningExpertBrand={runningExpertBrand}
            nbOfCharactersToTriggerSearch={nbOfCharactersToTriggerSearch}
            saleCategory={saleCategory}
        />
    )
}

MainMenu.propTypes = {
    logoImageWithText: PropTypes.objectOf(PropTypes.object).isRequired,
    logoImageOnly: PropTypes.objectOf(PropTypes.object).isRequired,
    goToCartPage: PropTypes.func.isRequired,
    goToProfilePage: PropTypes.func.isRequired,
    closeMiniCart: PropTypes.func.isRequired,
    goToCheckoutPage: PropTypes.func.isRequired,
    category: PropTypes.arrayOf(PropTypes.any).isRequired,
    searchFAQ: PropTypes.arrayOf(PropTypes.any).isRequired,
    cart: PropTypes.objectOf(PropTypes.any).isRequired,
    isOpenMiniCart: PropTypes.bool.isRequired,
    refMiniCart: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(DomElement) }),
    ]).isRequired,
    node: PropTypes.any,
    placeholderSearch: PropTypes.string,
    onFlyoutToggle: PropTypes.func,
    categorySidebar: PropTypes.any,
    setSearchHitsOpen: PropTypes.any,
    searchHitsOpen: PropTypes.any,
    themeName: PropTypes.any,
    setThemeName: PropTypes.any,
    absoluteTeamsportBrand: PropTypes.any,
    runningExpertBrand: PropTypes.any,
    context: PropTypes.object.isRequired,
    goToWishlistPage: PropTypes.func.isRequired,
    nbOfCharactersToTriggerSearch: PropTypes.number,
}

export default MainMenu
