import React from 'react'
import Link from 'next/link'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { FormattedHTMLMessage } from 'react-intl'
import { ReactComponent as IconArrowLeft } from '../../../../icons/sport2000-icons/arrowleft.svg'
import FullPageWidthWrapper from '../../molecules/Layout/FullPageWidthWrapper'
import List from '../../molecules/List'
import { topCategoryType } from '../MainMenu/types'

const TopBar = ({ topCategories, headline, themeName }) => (
  <FullPageWidthWrapper customClasses={'bg-blacksport-90 hidden lg:block'}>
    <div
      id={'topBarWrapper'}
      className={classnames('o-wrapper top-bar', {
        'wrapper-sport py-10px': themeName === '',
      })}
    >
      {themeName && (
        <Link href={'/'}
          className={classnames('top-bar--expert-area', {
            'theme--green': themeName === 'theme-absolute-teamsport',
            'theme--blue': themeName === 'theme-running-expert',
          })}>
          <IconArrowLeft width={20} height={20} />
          <FormattedHTMLMessage id={'topBar.expertArea'} />
        </Link>
      )}
      <div>{headline}</div>
      <List
        links={topCategories}
        variant={classnames('top-nav', 'flex-grow', 'inline-flex', 'list-none', 'justify-end')}
        itemVariant={classnames('pl-5')}
      />
    </div>
  </FullPageWidthWrapper>
)
TopBar.propTypes = {
  topCategories: PropTypes.arrayOf(topCategoryType),
  headline: PropTypes.string.isRequired,
  themeName: PropTypes.any,
}

export default TopBar
