import React from 'react'
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl'
import { useDeviceType } from '@frontastic/catwalk/src/js/helper/hooks/useDeviceType'
import { Hit } from 'react-instantsearch-core'
import { ReactComponent as LocationIcon } from '../../../../icons/sport2000-icons/location.svg'
import Link from 'next/link'

export type Props = {
  searchResult: any,
  valueText: any,
  selectedCategoryOption: any
  isFallbackSearch: boolean
} & WrappedComponentProps

const RUNNING_EXPERT_CONCEPT = 'running_expert'
const ABSOLUTE_RUN_CONCEPT = 'absolute_run'
const ABSOLUTE_TEAMSPORT_CONCEPT = 'absolute_teamsport'

const FUSSBALL_CATEGORY = 'fussball'
const RUNNING_CATEGORY = 'running'

const SearchStoreHits: React.FC<Props> = ({
  searchResult,
  selectedCategoryOption,
  isFallbackSearch,
}: Props) => {
  if (!searchResult) {
    return null
  }
  const { hits } = searchResult
  const { indexName } = searchResult
  if (!hits || !hits.length) {
    return null
  }

  const selectedCategoryName = selectedCategoryOption && selectedCategoryOption.name && selectedCategoryOption.name.toLowerCase()

  const getStoreByCategory = () => {
    if (selectedCategoryName === null || isFallbackSearch) {
      return hits
    }

    if (selectedCategoryName === FUSSBALL_CATEGORY) {
      return hits.filter((store) => store.specialization_logos && store.specialization_logos.indexOf(ABSOLUTE_TEAMSPORT_CONCEPT) !== -1)
    }

    if (selectedCategoryName === RUNNING_CATEGORY) {
      return hits.filter((store) => (store.specialization_logos && store.specialization_logos.indexOf(RUNNING_EXPERT_CONCEPT) !== -1)
        || (store.specialization_logos && store.specialization_logos.indexOf(ABSOLUTE_RUN_CONCEPT) !== -1))
    }

    return []
  }

  const filteredStoreByCategory = getStoreByCategory()

  const closeSearch = () => {
    const customEvent = new Event('onCloseSearch')

    document.dispatchEvent(customEvent)
  }

  return (
    <>
      {filteredStoreByCategory && filteredStoreByCategory.length > 0 && (
        <div className={'search--store search--common'}>
          <h4 className={'search-hits--headline hidden lg:block'}>
            <FormattedMessage id={'search.searchHits.categoryStore'} />
          </h4>

          <div
            className={'search-hits--list search--store-wrapper search--common-wrapper'}
            data-insights-index={indexName}
          >
            {filteredStoreByCategory.map((hit: Hit) => (
              <div
                className={'relative search-store-hit search-insights-hit'}
                key={hit.objectID}
                data-insights-object-id={hit.objectID}
                data-insights-position={hit.__position}
                data-insights-query-id={hit.__queryID}
              >
                <Link
                  href={`${hit.url}`}
                  className={'search--store-content'}
                  onClick={closeSearch}
                >

                  <div
                    className={'search--store-content--item search--common-wrapper-content'}
                  >
                    <LocationIcon
                      width={20}
                      height={20}
                    />
                    <div
                      className={'search--store-content--item-name search--common-wrapper-content-name'}
                    >
                      <div>
                        <span
                          className={'ais-Highlight'}
                          dangerouslySetInnerHTML={{ __html: hit._highlightResult.name.value }}
                        />
                        {' '}
                        {hit.city && (<span>{hit.city}</span>)}
                      </div>
                    </div>
                  </div>
                </Link>
                <div className={'search--common-wrapper-category absolute right-0 lg:hidden'}>
                  <FormattedMessage id={'search.searchHits.categoryStore'} />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  )
}

export default injectIntl(SearchStoreHits)
