"use client";
import React, { memo, useState } from "react";
import Link, { LinkProps } from "next/link";
import { useRouter } from "next/navigation";
import LoadingFullScreenPortal from "./loading-fullscreen-portal";

interface TransitionLinkProps extends LinkProps {
  children: React.ReactNode;
  href: string;
  className?: string;
}

function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const TransitionLinkComp: React.FC<TransitionLinkProps> = ({
  children,
  href,
  onClick: onClickProp,
  ...props
}) => {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);

  const handleTransition = async (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {

    e.preventDefault();
    setIsLoading(true); // Show the loader
    await sleep(1500); // Allow the transition effect
    router.push(href); // Navigate to the next page
    setTimeout(() => setIsLoading(false), 1000); // Hide the loader
    onClickProp?.(e);
  };


  return (
    <>
      <Link {...props} href={href} onClick={handleTransition}>
        {children}
      </Link>
      {isLoading && (
        <LoadingFullScreenPortal />
      )}
    </>
  );
};

export const TransitionLink = memo(TransitionLinkComp);