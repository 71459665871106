import React from 'react'
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl'
import classnames from 'classnames'
import Link from 'next/link'

export type Props = {
  searchResult: any,
  searchHitsOpen: boolean,
  selectedCategoryOption: any
  isFallbackSearch: boolean
  onCloseSearchBox?: VoidFunction
} & WrappedComponentProps

const RUNNING_EXPERT_PATH = '/running-experts'
const RUNNING_TRENDS_PATH = '/campaign/running-trends'
const RUNNING_HIGH_PATH = '/campaign/runners-high'
const OUTDOOR_PATH = '/campaign/mission-outdoor'
const FOOTBALL_PATH = '/absolute-teamsport'

const RUNNING_CATEGORY = 'running'
const OUTDOOR_CATEGORY = 'outdoor'
const FOOTBALL_CATEGORY = 'fussball'

const SearchBlogHits: React.FC<Props> = ({
  searchResult, searchHitsOpen, selectedCategoryOption, isFallbackSearch, onCloseSearchBox
}: Props) => {
  if (!searchResult) {
    return null
  }
  const { hits } = searchResult
  const numberOfHits = searchResult?.nbHits || 0
  const { indexName } = searchResult

  const hasPathInBlogCategory = (blog, categoryPath) => blog.url && blog.url.toLowerCase().indexOf(categoryPath) !== -1

  const filterBlogByCategories = () => {
    const selectedCategory = selectedCategoryOption && selectedCategoryOption.name && selectedCategoryOption.name.toLowerCase()

    if (!hits && !hits.length) {
      return null
    }

    if (selectedCategory === null || isFallbackSearch) {
      return hits
    }

    if (selectedCategory === FOOTBALL_CATEGORY) {
      return hits.filter((blog) => hasPathInBlogCategory(blog, FOOTBALL_PATH))
    }

    if (selectedCategory === OUTDOOR_CATEGORY) {
      return hits.filter((blog) => hasPathInBlogCategory(blog, OUTDOOR_PATH))
    }

    if (selectedCategory === RUNNING_CATEGORY) {
      return hits.filter((blog) => hasPathInBlogCategory(blog, RUNNING_HIGH_PATH)
        || hasPathInBlogCategory(blog, RUNNING_EXPERT_PATH) || hasPathInBlogCategory(blog, RUNNING_TRENDS_PATH))
    }

    return []
  }

  return (
    <>
      {filterBlogByCategories() && filterBlogByCategories().length > 0 && (
        <div className={classnames('search--blog', {
          hidden: !searchHitsOpen,
        })}
        >
          <div className={'flex justify-between lg:justify-start mb-4'}>
            <div className={'flex items-center'}>
              <h4 className={'search-hits--headline mb-0'}>
                <FormattedMessage id={'search.searchHits.blogHeadline'} />
              </h4>
              <div className={'search-hits-count ml-2 lg:mr-4'}>
                (
                <span>{numberOfHits}</span>
                )
              </div>
            </div>
          </div>

          <div
            className={'search-hits--list blog-category-wrapper'}
            data-insights-index={indexName}
          >
            {filterBlogByCategories().map((hit) => {
              const blogURL = () => {
                if (hit.blogCategory && hit.slug) {
                  const blogUrl = hit.slug

                  return `/blog/${blogUrl}`
                }

                return hit.url
              }

              return (
                <Link
                  href={blogURL()}
                  className={'blog-category-item'}
                  key={hit.objectID}
                  data-insights-object-id={hit.objectID}
                  data-insights-position={hit.__position}
                  data-insights-query-id={hit.__queryID}
                  onClick={() => onCloseSearchBox?.()}
                >
                  {hit.image ? (
                    <img
                      className={'blog-category-item-image'}
                      src={hit.image}
                      alt={hit.seoTitle}
                      title={hit.seoTitle}
                      width={235}
                      height={262}
                      draggable={false}
                      loading={'lazy'}
                      decoding={'async'}
                    />
                  ) : (
                    <div className={'search-hit--placeholder'} />
                  )}

                  <div className={'blog-category-item-content'}>
                    {hit.tags && (
                      <div className={'blog-category-item-tags hidden lg:block'}>
                        <span className={'blog-category-item-tags-text'}>{hit.tags}</span>
                      </div>
                    )}
                    {hit.seoTitle && (
                      <div
                        className={'blog-category-item-headline'}
                      >
                        {hit.seoTitle}
                      </div>
                    )}
                    <div className={'blog-category-item-link'}>
                      <FormattedMessage id={'blog.showDetail'} />
                    </div>
                  </div>
                </Link>
              )
            })}
          </div>
        </div>
      )}
    </>
  )
}

export default injectIntl(SearchBlogHits)
