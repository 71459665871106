import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { injectIntl } from 'react-intl'
import { useDeviceType } from '@frontastic/catwalk/src/js/helper/hooks/useDeviceType'
import Reference from '@frontastic/catwalk/src/js/component/reference'
import MediaImage from '@frontastic/catwalk/src/js/mediaImage'
import Link from 'next/link'

const BrandFlyout = ({
  itemId, expandedItemId, onClose, brandList, brandLogo,
}) => {
  const isDesktop = useDeviceType() === 'desktop'

  const keyboardHandler = (event) => {
    if (event.key === 'Escape') {
      onClose()
    }
  }

  const bodyClickHandler = (event) => {
    if (event.target.closest('.main-menu-wrapper, .flyout')) {
      return
    }

    onClose()
  }

  const renderBrandLogo = () => {
    if (!brandLogo || !brandLogo.data?.length) {
      return null
    }

    return (
      <div className={'flyout-left'}>
        {brandLogo.title && (<p>{brandLogo.title}</p>)}
        <div className={'brand-logo--wrapper desktopxx:pb-6'}>
          {brandLogo.data.map((brandLogoItem) => (
            <Link href={brandLogoItem.reference?.link || '#'} key={brandLogoItem.title} onClick={onClose}>
              <MediaImage media={brandLogoItem.media} height={63} />
            </Link>
          ))}
        </div>
      </div>
    )
  }

  const renderBrandList = () => {
    if (!brandList || !brandList.data?.length) {
      return null
    }

    return (
      <div className={'flyout-right'}>
        {brandList.title && (<p>{brandList.title}</p>)}
        <div className={'brand-list--wrapper'}>
          {brandList.data.map((brandListItem, index) => (
            <Link
              className={`${index > 9 && 'ml-6'}`}
              href={brandListItem.reference?.link || '#'}
              key={brandListItem.title}
              onClick={onClose}
            >
              {brandListItem.title}
            </Link>
          ))}
        </div>
      </div>
    )
  }

  useEffect(() => {
    if (isDesktop) {
      window.addEventListener('keydown', keyboardHandler)
      document.body.addEventListener('click', bodyClickHandler)
    }

    return () => {
      window.removeEventListener('keydown', keyboardHandler)
      document.body.removeEventListener('click', bodyClickHandler)
    }
  }, [])

  return (
    <div className={classnames('flyout flyout-brand-category flex flex-wrap', {
      flyout__open: expandedItemId === itemId,
    })}
    >
      {renderBrandLogo()}
      {renderBrandList()}
    </div>
  )
}

BrandFlyout.propTypes = {
  expandedItemId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  itemId: PropTypes.string.isRequired,
  brandLogo: PropTypes.any,
  brandList: PropTypes.any,
}

export default injectIntl(BrandFlyout)
