import React, { useState } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import { CheckIcon, CloseIcon } from '../../atoms/icon'
import IconButton from '../../atoms/button/IconButton'

const ErrorMessageProduct = ({ intl, removedItems = [], reducedLineItems = [] }) => {
    const [active, setActive] = useState(removedItems.length || reducedLineItems.length)

    const onCloseMessage = () => {
        setActive(!active)
    }

    const renderProduct = (product) => {
        const color = product.variant.attributes.att_prod_color_name_mf
        const productSize = () => product.variant.attributes.attr_size

        return (
            <li className={'error-message-product-detail-list-product-item'}>
                <div className={'error-message-product-detail-list-title'}>
                    {product.name}
                </div>
                <div className={'error-message-product-detail-list-variant'}>
                    {color && (
                        <div className={'error-message-product-detail-list-variant-color'}>
                            <FormattedMessage id={'cart.color'} />
                            {' '}
                            <span>{color}</span>
                            {' , '}
                        </div>
                    )}

                    {productSize() && (
                        <div className={'error-message-product-detail-list-variant-size'}>
                            <FormattedMessage id={'cart.size'} />
                            {' '}
                            <span>{productSize()}</span>
                        </div>
                    )}
                </div>
            </li>
        )
    }

    if (!active) {
        return null
    }

    return (
        <div className={'error-message'}>
            <div className={'error-message-product'}>
                <div className={'error-message-product-icon'}>
                    <CheckIcon width={24} height={24} />
                </div>
                <div className={'error-message-product-detail'}>
                    {removedItems.length > 0 && (
                        <div className={'error-message-product-detail-title'}>
                            <span>{removedItems.length}</span>
                            {' '}
                            {removedItems.length > 1 ? <FormattedMessage id={'cart.removedItemsTitle'} />
                                : <FormattedMessage id={'cart.removedItemTitle'} />}
                        </div>
                    )}

                    {removedItems.length > 0 && (
                        <div className={'error-message-product-detail-list'}>
                            <ul className={'error-message-product-detail-list-product'}>
                                {removedItems.map((item) => renderProduct(item))}
                            </ul>
                        </div>
                    )}

                    {reducedLineItems.length > 0 && (
                        <div className={'error-message-product-detail-title'}>
                            {reducedLineItems.length > 1 ? <FormattedMessage id={'cart.reducedItemsTitle'} />
                                : <FormattedMessage id={'cart.reducedItemTitle'} />}
                        </div>
                    )}

                    {reducedLineItems.length > 0 && (
                        <div className={'error-message-product-detail-list'}>
                            <ul className={'error-message-product-detail-list-product'}>
                                {reducedLineItems.map((item) => renderProduct(item))}
                            </ul>
                        </div>
                    )}

                </div>
                <div className={'error-message-product-close'}>
                    <IconButton
                        className={'error-message-product-close-button'}
                        onClick={onCloseMessage}
                        type={'button'}
                        ariaLabel={intl.formatMessage({ id: 'account.edit' })}
                        icon={<CloseIcon width={20} height={20} />}
                    />
                </div>
            </div>
        </div>
    )
}

ErrorMessageProduct.propTypes = {
    intl: PropTypes.object.isRequired,
    removedItems: PropTypes.arrayOf(PropTypes.object).isRequired,
    reducedLineItems: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default injectIntl(ErrorMessageProduct)
